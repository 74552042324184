





























































import Vue from 'vue'
import Component from 'vue-class-component'
import DocumentBase from '@/components/pdf/DocumentBase.vue'
import { Prop } from 'vue-property-decorator'
import { DocumentBaseForm } from '@/presentation/affiliate/pdf/DocumentBaseForm'
import dayjs from 'dayjs'
import { Getter } from 'vuex-class'
import { CompletedCorpAll } from '@/gateway/affiliate/model/CorpAll'
import { koEnum } from '@/lokalise/enumLocale/ko'
import { BusinessCategory } from '@/presentation/affiliate/CompanyInfoField'
import { KoreanBank } from '@/data/KoreanBank'

@Component({
  components: { DocumentBase }
})
export default class UsageAgreement extends Vue {
  @Prop(String) containerClass!: string
  @Prop(String) title!: string
  @Prop(Number) currentPage!: number
  @Prop(Number) totalPage!: number

  @Getter getCorpAllState: any

  corpAll!: CompletedCorpAll

  get getToday (): string {
    return dayjs(new Date()).format('YYYY년 MM월 DD일')
  }

  forms: Array<Array<DocumentBaseForm>> = []
  clientSignatureData!: {
    name: string
    line1: string
    line2: string
    ceo_name: string
  }

  private getFirstPageData (): Array<DocumentBaseForm> {
    const corpAll: CompletedCorpAll = this.corpAll
    const bizLicenseNumber = `${corpAll.biz_license_number.substr(0, 3)}-${corpAll.biz_license_number.substr(3, 2)}-${corpAll.biz_license_number.substr(5)}`
    const phoneNumber = Number(this.corpAll.phone_number)
    const fullPhoneNumber = `${this.corpAll.phone_code} ${phoneNumber}`
    const companyInfo: Array<Array<string>> = [
      ['상호(법인)명', corpAll.name],
      ['사업자등록번호', bizLicenseNumber, '사업체구분', koEnum[BusinessCategory[corpAll.biz_category]]],
      ['대표자명', corpAll.ceo_information_list[0].name, '회사 전화번호', fullPhoneNumber],
      ['회사 주소', `${corpAll.line1} ${corpAll.line2}`]
    ]
    const krBankName: string = KoreanBank.find(bank => bank.name_en === corpAll.bank_name)?.name_kr || corpAll.bank_name
    const accountInfo: Array<Array<string>> = [
      ['은행명', krBankName, '예금주', corpAll.account_holder],
      ['계좌번호', corpAll.bank_account_number]
    ]
    const feeInfo: Array<string> = corpAll.fee_terms.split('\n')
    const cutOffInfo: Array<string> = corpAll.cut_off_time.split('\n')
    return [
      {
        title: '1. 사업자 정보',
        type: 'table',
        data: companyInfo
      },
      {
        title: '2. 계좌 번호',
        type: 'table',
        data: accountInfo
      },
      {
        title: '3. 서비스 이용 수수료',
        type: 'textarea',
        data: feeInfo,
        subText: '* 환전수수료, 송금 수수료, 외국 협력업자 지급 수수료 등 결제대행을 위한 모든 수수료가 포함됩니다.'
      },
      {
        title: '4. 컷오프 시간',
        type: 'textarea',
        data: cutOffInfo,
        subText: '* 지불하기 서비스 이용시에만 적용되며, 결제 신청시에 표시된 환율이 담보되는 하루 중 가장 늦은 시간을 말합니다.'
      }
    ]
  }

  private getSecondPageData (): Array<DocumentBaseForm> {
    const corpAll: CompletedCorpAll = this.corpAll
    const specialTerms: Array<string> = corpAll.special_terms.split('\n')
    return [
      {
        subTitle: '제1조(목적)',
        type: 'text',
        data: '본 약정(서)는 “고객”과 “주식회사 센트비(이하 “회사”)”간에 “센트비즈 서비스 이용약관”과 별도로 이용수수료 등을 정함에 그 목적이 있다. 본인은 회사의 센트비즈 서비스를 이용함에 있어 “센트비즈 서비스 이용약관”, “센트비 개인정보 처리방침”, “전자금융거래 이용약관”이 적용됨을 승인하고 다음과 같이 약정한다.'
      },
      {
        subTitle: '제2조(약정의 효력)',
        type: 'text',
        data: '① 본 약정은 체결시부터 그 효력이 발생하고 서면에 의하여 별도의 의사표시를 하지 않는 한 유효기간은 1년씩 자동연장 되는 것으로 한다.\n' +
          '② 본 약정이 종료하는 경우에도 “센트비즈 서비스 이용약관” 제4조 제2항에서 정한 바와 같이 결제대행이 완료되지 않은 기신청 건에 대하여는 해당 결제대행 및 정산 완료시까지 이용계약의 효력이 유지되는 것으로 한다.'
      },
      {
        subTitle: '제3조(자료의 제출)',
        type: 'text',
        data: '“고객”은 “고객거래확인서”를 성실히 작성하고 필요 서류를 제출하는 등 센트비즈 서비스의 원활한 이용을 위하여 협조하여야 한다. '
      },
      {
        subTitle: '제4조(이용료)',
        type: 'text',
        data: '“고객”은 “회사”가 제공하는 “센트비즈 서비스” 이용료로 [4. 서비스 이용수수료]에 기재된 수수료를 “회사”에 납부하여야 한다. '
      },
      {
        subTitle: '제5조(기타특약사항)',
        type: 'textarea',
        data: specialTerms
      },
      {
        type: 'text',
        data: '“고객”은 “센트비즈서비스 이용약관”을 수령함과 동시에 충분한 설명을 듣고 내용을 숙지하였으며, 이용약정서상 기재된 사항 및 약관내용을 준수하여 서비스를 신청하고 계약의 성립을 입증하기 위하여 본 약정서 2통을 작성하고 “고객”, “회사” 각각 기명 날인하여 각자 1통식 보관한다.'
      }
    ]
  }

  created () {
    this.corpAll = this.getCorpAllState
    this.forms = Array(...[
      this.getFirstPageData(),
      this.getSecondPageData()
    ])
    this.clientSignatureData = {
      name: this.corpAll.name,
      line1: this.corpAll.line1,
      line2: this.corpAll.line2,
      ceo_name: this.corpAll.ceo_information_list[0].name
    }
  }
}
