



























































import Vue from 'vue'
import Component from 'vue-class-component'
import { DocumentBaseForm } from '@/presentation/affiliate/pdf/DocumentBaseForm'
import { Prop } from 'vue-property-decorator'

@Component
export default class DocumentBase extends Vue {
  @Prop(Array) forms!: Array<DocumentBaseForm>

  columnClass (index: number, columnClass?: string): string {
    if (index % 2 === 0) return columnClass || 'column-title'
    return 'column-content'
  }
}
