




























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { DocumentDownload, DocumentTypeOption, DownloadOptions } from '@/presentation/affiliate/DocumentDownload'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import UsageAgreement from '@/components/pdf/UsageAgreement.vue'
import TradeConfirmation from '@/components/pdf/TradeConfirmation.vue'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { Getter, Mutation } from 'vuex-class'
import MemberInfo from '@/models/MemberInfo'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { TradeConfirmation, UsageAgreement, BasicDialog }
})
export default class Download extends Vue {
  @Getter getCorpAllState: any
  @Mutation setCorpAllState: any

  memberInfo!: MemberInfo

  document: DocumentDownload = new DocumentDownload()
  forms: Array<DownloadOptions> = []
  documentTypeOptions: Array<DocumentTypeOption> = []
  corpAll!: CorpAll

  onClickSubButton () {
    this.corpAll.status = CorpStatus.FORM_COMPLETE
    this.setCorpAllState(this.corpAll)
    this.$router.push('documents')
  }

  onClickSubmit () {
    this.$router.push('upload')
  }

  private setMemberInfo () {
    if (!MemberInfoGateway.hasAuthInfo()) {
      this.$router.replace('/signUp')
      return
    }
    this.memberInfo = MemberInfoGateway.getMemberInfo()
  }

  private async setCorpAll (): Promise<void> {
    const corpId = this.memberInfo.corp_id
    this.corpAll = await GetCorpAll.getInstance().request({ corp_id: corpId })
    this.setCorpAllState(this.corpAll)
  }

  async created () {
    this.setMemberInfo()
    await this.setCorpAll()
    const isProcessing = this.corpAll.status === Number(CorpStatus.PROCESSING)
    if (isProcessing) await this.$router.replace('account')
    const isFormProcessing = Number(this.corpAll.status) < Number(CorpStatus.FORM_COMPLETE)
    if (isFormProcessing) {
      this.$message({ message: '문서작성을 먼저 완료해 주세요', type: 'warning' })
      return this.$router.replace('documents')
    }
    if (!this.corpAll.biz_category) return
    this.documentTypeOptions = Array(...this.document.getDocumentTypeOptions())
    this.forms = Array(...this.document.getForms(this.corpAll.biz_category))
  }
}
